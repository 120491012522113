@tailwind base;
@tailwind components;
@tailwind utilities;


.z-up {
  z-index: 999999999999999999 !important;
}

.z-up_1 {
  z-index: 1 !important;
}

.pb-1 {
  padding-bottom: 2rem !important;
}

.pb-2 {
  padding-bottom: 4rem !important;
}

.mb-2 {
  margin-bottom: 4rem !important;
}


.faqTitle .white {
  color:#fff !important;

}

.faqContent .white {
  color:#fff !important;

}

.border_none {
  border: none !important;
}
#border_none {
  border: none !important;
}

.margin_left_20 {
  margin-left: 20px !important;
}

.margin_left__20 {
  margin-left: -20px !important;
}


.white {
  color:#fff !important;

}



/* form */


.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  justify-content: center;
  align-items: center;
}

.modal.show {
  display: flex;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 300px;
  text-align: center;
  border-radius: 8px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}






.subscriptions {
  background-color: #121212;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.subscriptions .div-6 {
  background-color: #121212;
  height: 3158px;
  position: relative;
  width: 390px;
}

.subscriptions .text-wrapper-25 {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 60px;
  font-weight: 400;
  left: 29px;
  letter-spacing: -2.4px;
  line-height: 55.2px;
  position: absolute;
  top: 228px;
  width: 335px;
}

.subscriptions .text-wrapper-26 {
  color: #ffffffb2;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 29px;
  letter-spacing: 0;
  line-height: 27.7px;
  position: absolute;
  top: 1037px;
  width: 336px;
}

.subscriptions .group-38 {
  height: 89px;
  left: 30px;
  position: absolute;
  top: 1691px;
  width: 179px;
}

.subscriptions .group-39 {
  border: 0.5px solid;
  border-color: #ffffff;
  border-radius: 44.5px;
  height: 89px;
  left: 88px;
  position: absolute;
  top: 0;
  width: 89px;
}

.subscriptions .arrow-3 {
  height: 32px;
  left: 28px;
  position: absolute;
  top: 28px;
  width: 32px;
}

.subscriptions .text-wrapper-27 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 600;
  left: 0;
  letter-spacing: 2.8px;
  line-height: 22.1px;
  position: absolute;
  top: 36px;
  white-space: nowrap;
}

.subscriptions .first-name {
  background: transparent;
  border: none;
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 30px;
  letter-spacing: 0;
  line-height: 22.5px;
  padding: 0;
  position: absolute;
  top: 1286px;
  width: 335px;
  background-color:inherit;

}

/* Add this hover selector */
.subscriptions .first-name:hover {
  text-decoration: none !important;
}

/* Add this focus selector */
.subscriptions .first-name:focus {
  text-decoration: none !important;
  outline: none;

}

.subscriptions .line-33 {
  height: 1px;
  left: 30px;
  object-fit: cover;
  position: absolute;
  top: 1316px;
  width: 335px;
}

.subscriptions .last-name {
  background: transparent;
  border: none;
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 30px;
  letter-spacing: 0;
  line-height: 22.5px;
  padding: 0;
  position: absolute;
  top: 1352px;
  width: 335px;
  background-color:inherit;

}

/* Add this hover selector */
.subscriptions .last-name:hover {
  text-decoration: none !important;
}

/* Add this focus selector */
.subscriptions .last-name:focus {
  text-decoration: none !important;
  outline: none;

}

.subscriptions .line-34 {
  height: 1px;
  left: 30px;
  object-fit: cover;
  position: absolute;
  top: 1382px;
  width: 335px;
}

.subscriptions .email-address {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 30px;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  top: 1418px;
  width: 335px;
  background-color:inherit;

}

/* Add this hover selector */
.subscriptions .email-addressr:hover {
  text-decoration: none !important;
}

/* Add this focus selector */
.subscriptions .email-address:focus {
  text-decoration: none !important;
  outline: none;

}

.subscriptions .line-35 {
  height: 1px;
  left: 30px;
  object-fit: cover;
  position: absolute;
  top: 1449px;
  width: 335px;
}

.subscriptions .phone-number {
  color: #ffffff;
  background-color:inherit;

  font-family: "Basic", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 30px;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  top: 1485px;
  width: 335px;
}

/* Add this hover selector */
.subscriptions .phone-number:hover {
  text-decoration: none !important;
}

/* Add this focus selector */
.subscriptions .phone-number:focus {
  text-decoration: none !important;
  outline: none;

}

.subscriptions .line-36 {
  height: 1px;
  left: 30px;
  object-fit: cover;
  position: absolute;
  top: 1516px;
  width: 335px;
}

.subscriptions .interested-in {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 30px;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  top: 1552px;
  width: 335px;
  background-color:inherit;
}
/* Add this hover selector */
.subscriptions .interested-in:hover {
  text-decoration: none !important;
}

/* Add this focus selector */
.subscriptions .interested-in:focus {
  text-decoration: none !important;
  outline: none;

}

.subscriptions .line-37 {
  height: 1px;
  left: 30px;
  object-fit: cover;
  position: absolute;
  top: 1583px;
  width: 335px;
}

.subscriptions .message {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 30px;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  top: 1619px;
  width: 335px;
  background-color:inherit;

}

/* Add this hover selector */
.subscriptions .message:hover {
  text-decoration: none !important;
}

/* Add this focus selector */
.subscriptions .message:focus {
  text-decoration: none !important;
  outline: none;

}

.subscriptions .line-38 {
  height: 1px;
  left: 30px;
  object-fit: cover;
  position: absolute;
  top: 1650px;
  width: 335px;
}

.subscriptions .line-39 {
  height: 1px;
  left: 29px;
  object-fit: cover;
  position: absolute;
  top: 2298px;
  width: 336px;
}

.subscriptions .FOOTER-4 {
  display: flex !important;
  height: 118px !important;
  width: 93px !important;
}

.subscriptions .FOOTER-5 {
  left: 0 !important;
  position: absolute !important;
  top: 2299px !important;
}

.subscriptions .vector-4 {
  height: 6px;
  left: 354px;
  position: absolute;
  top: 1563px;
  width: 11px;
}

.subscriptions .subscription-2 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 600;
  left: 29px;
  letter-spacing: 0;
  line-height: 15px;
  opacity: 0.75;
  position: absolute;
  top: 896px;
  width: 211px;
}

.subscriptions .mask-group-7 {
  height: 227px;
  left: 29px;
  position: absolute;
  top: 592px;
  width: 336px;
}

.subscriptions .reach-out-about {
  color: #ffffffe6;
  font-family: "Basic", Helvetica;
  font-size: 45px;
  font-weight: 400;
  left: 29px;
  letter-spacing: -1.8px;
  line-height: 42.3px;
  position: absolute;
  top: 932px;
  width: 336px;
}

.subscriptions .text-wrapper-28 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 29px;
  letter-spacing: 0;
  line-height: 28.4px;
  opacity: 0.8;
  position: absolute;
  top: 300px;
  width: 335px;
}

.subscriptions .group-40 {
  height: 89px;
  left: 29px;
  position: absolute;
  top: 432px;
  width: 220px;
}

.subscriptions .group-41 {
  border: 0.5px solid;
  border-color: #ffffff;
  border-radius: 44.5px;
  height: 89px;
  left: 128px;
  position: absolute;
  top: 0;
  width: 89px;
}

.subscriptions .line-40 {
  height: 1px;
  left: 29px;
  object-fit: cover;
  position: absolute;
  top: 561px;
  width: 336px;
}

.subscriptions .mask-group-8 {
  height: 393px;
  left: 29px;
  position: absolute;
  top: 1833px;
  width: 336px;
}

.subscriptions .group-42 {
  background-color: #121212;
  height: 105px;
  left: 0;
  position: fixed;
  top: 0;
  width: 390px;
}

.subscriptions .NAV-4 {
  left: 29px !important;
  top: 39px !important;
}

.subscriptions .group-101-19 {
  height: 65px !important;
  left: 316px !important;
  position: fixed !important;
  top: 771px !important;
  width: 65px !important;
}


.FOOTER-instance {
  left: 0 !important;
  position: absolute !important;
  top: 1700px !important;
  z-index: 0;
  background-color: #121212;
}

.container {
  display: grid;
  place-items: center;
  height: 100vh !important;
  width: 100vw !important;
}

.NAV-instance {
  left: 29px !important;
  position: fixed !important;
  top: 39px !important;
}

.NAV {
  height: 42px;
  width: 347px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.NAV .logo {
  display: block;
  height: 39px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 140px;
}

.NAV .group {
  height: 39px;
  position: relative;
  width: 142px;
}

.NAV .text-wrapper-6 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 44px;
  letter-spacing: -0.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 96px;
}

.NAV .img {
  height: 39px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 38px;
}

.NAV .menu-wrapper {
  display: block;
  height: 30px;
  left: 297px;
  position: absolute;
  top: 5px;
  width: 50px;
  cursor: pointer;
}

.NAV .menu-2 {
  height: 11px;
  left: 10px;
  position: relative;
  top: 16px;
  width: 33px;
}

.NAV .line {
  height: 2px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 33px;
}

.NAV .line-2 {
  height: 2px;
  left: 10px;
  position: absolute;
  top: 8px;
  width: 23px;
}

.side-menu {
  position: fixed;
  top: 0;
  right: -375px;
  height: 100%;
  width: 375px;
  background-color: #121212;
  transition: right 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.side-menu.open {
  right: 0;
}

.side-menu a {
  color: white;
  text-decoration: none;
  margin: 10px 0;
}














.basic-regular {
  font-family: "Basic", sans-serif;
  font-weight: 400;
  font-style: normal;
}






/* For more specificity if needed */
input.custom-input-class::placeholder {
  color: #333333 !important; /* Force the color change if needed */
  opacity: 1;
}



input.custom-input-class {
  background-color: #f0f0f0;
  color: #333333 !important; /* Use !important to override other styles */
  border: 1px solid #cccccc;
}

select.custom-select-class {
  background-color: #f0f0f0;
  color: #888;
  border: 1px solid #cccccc;
  padding: 10px;
  font-size: 16px;
}

select.custom-select-class option[disabled] {
  color: #888888; /* Placeholder color */
}

select.custom-select-class option {
  color: #333333; /* Normal option color */
}

select.custom-select-class:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

select.custom-select-class:hover {
  border-color: #007bff;
}

/* form input[type="text"],
form input[type="email"],
form input[type="password"] {
  color: #fff !important;
} */
/* Dark theme for form inputs */
form input.dark[type="text"],
form input.dark[type="email"],
form input.dark[type="password"],
form input.dark[type="number"],
form input.dark[type="tel"],
form input.dark[type="url"],
form input.dark[type="search"],
form input.dark[type="date"],
form input.dark[type="datetime-local"],
form input.dark[type="month"],
form input.dark[type="week"],
form input.dark[type="time"],
form select.dark,
form textarea.dark {
  color: #ffffff; /* Light text color */
  background-color: #333333; /* Dark background */
  border: 1px solid #555555; /* Darker border */
  padding: 10px;
  font-size: 16px;
}

/* Dark theme placeholder styling */
form input.dark::placeholder,
form textarea.dark::placeholder {
  color: #bbbbbb; /* Lighter gray placeholder text */
  opacity: 1;
}

/* Focus state for dark theme */
form input.dark:focus,
form

/* Light theme for form inputs */
form input.light[type="text"],
form input.light[type="email"],
form input.light[type="password"],
form input.light[type="number"],
form input.light[type="tel"],
form input.light[type="url"],
form input.light[type="search"],
form input.light[type="date"],
form input.light[type="datetime-local"],
form input.light[type="month"],
form input.light[type="week"],
form input.light[type="time"],
form select.light,
form textarea.light {
  color: #000000; /* Dark text color */
  background-color: #ffffff; /* Light background */
  border: 1px solid #cccccc; /* Light border */
  padding: 10px;
  font-size: 16px;
}

/* Light theme placeholder styling */
form input.light::placeholder,
form textarea.light::placeholder {
  color: #666666; /* Darker gray placeholder text */
  opacity: 1;
}

/* Focus state for light theme */
form input.light:focus,
form select.light:focus,
form textarea.light:focus {
  border-color: #007bff; /* Blue border on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Hover state for light theme */
form input.light:hover,
form select.light:hover,
form textarea.light:hover {
  border-color: #007bff;
}

.cart-icon,
.cart-sidebar {
  z-index: 2147483647 !important;
}

/* mobile */

/* about us mobile */

.color {
  color: #000 !important;
}

@media all and (max-width: 767px) {
.about-us {
  background-color: #121212;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.about-us .div-10 {
  background-color: #121212;
  height: 7583px;
  overflow: hidden;
  position: relative;
  width: 390px;
}

.about-us .text-wrapper-76 {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 60px;
  font-weight: 400;
  left: 29px;
  letter-spacing: -2.4px;
  line-height: 55.2px;
  position: absolute;
  top: 346px;
  width: 335px;
}

.about-us .text-wrapper-77 {
  color: #ffffffb2;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 29px;
  letter-spacing: 0;
  line-height: 27.7px;
  position: absolute;
  top: 1079px;
  width: 336px;
}

.about-us .who-we-are {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 600;
  left: 29px;
  letter-spacing: 0;
  line-height: 15px;
  opacity: 0.75;
  position: absolute;
  top: 896px;
  width: 211px;
}

.about-us .mask-group-15 {
  height: 227px;
  left: 29px;
  position: absolute;
  top: 592px;
  width: 336px;
}

.about-us .about-neutrafog {
  color: #ffffffe6;
  font-family: "Basic", Helvetica;
  font-size: 45px;
  font-weight: 400;
  left: 29px;
  letter-spacing: -1.8px;
  line-height: 42.3px;
  position: absolute;
  top: 932px;
  width: 336px;
}

.about-us .text-wrapper-78 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 29px;
  letter-spacing: 0;
  line-height: 28.4px;
  opacity: 0.8;
  position: absolute;
  top: 418px;
  width: 335px;
}

.about-us .line-61 {
  height: 1px;
  left: 29px;
  object-fit: cover;
  position: absolute;
  top: 561px;
  width: 336px;
}

.about-us .overlap-31 {
  border-radius: 5px;
  height: 290px;
  left: 12px;
  position: absolute;
  top: 1530px;
  width: 366px;
}

.about-us .text-wrapper-79 {
  color: #ffffffb2;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 36px;
  letter-spacing: 0;
  line-height: 27.7px;
  position: absolute;
  top: 73px;
  width: 286px;
}

.about-us .text-wrapper-80 {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 80px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 31px;
  white-space: nowrap;
  width: 209px;
}

.about-us .text-wrapper-81 {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 36px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 31px;
  white-space: nowrap;
}

.about-us .rectangle-37 {
  border: 1px solid;
  border-color: #737272;
  border-radius: 5px;
  height: 290px;
  left: 0;
  position: absolute;
  top: 0;
  width: 366px;
}

.about-us .overlap-32 {
  border-radius: 5px;
  height: 284px;
  left: 12px;
  position: absolute;
  top: 1842px;
  width: 366px;
}

.about-us .rectangle-38 {
  border: 1px solid;
  border-color: #737272;
  border-radius: 5px;
  height: 284px;
  left: 0;
  position: absolute;
  top: 0;
  width: 366px;
}

.about-us .overlap-33 {
  border-radius: 5px;
  height: 283px;
  left: 12px;
  position: absolute;
  top: 2148px;
  width: 366px;
}

.about-us .rectangle-39 {
  border: 1px solid;
  border-color: #737272;
  border-radius: 5px;
  height: 283px;
  left: 0;
  position: absolute;
  top: 0;
  width: 366px;
}

.about-us .value-listed-here {
  color: transparent;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 36px;
  letter-spacing: 0;
  line-height: 27.7px;
  position: absolute;
  top: 73px;
  width: 286px;
}

.about-us .text-wrapper-82 {
  color: #ffffff;
}

.about-us .text-wrapper-83 {
  color: #ffffffb2;
}

.about-us .value-listed-here-2 {
  color: transparent;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 36px;
  letter-spacing: 0;
  line-height: 27.7px;
  position: absolute;
  top: 162px;
  width: 286px;
}

.about-us .overlap-34 {
  height: 1409px;
  left: 0;
  position: absolute;
  top: 2495px;
  width: 390px;
}

.about-us .rectangle-40 {
  background-color: #ffffff;
  height: 1365px;
  left: 0;
  position: absolute;
  top: 0;
  width: 390px;
}



.about-us .line-62 {
  height: 1px;
  left: 29px;
  object-fit: cover;
  position: absolute;
  top: 142px;
  width: 336px;
}

.about-us .mask-group-16 {
  height: 399px;
  left: 29px;
  position: absolute;
  top: 175px;
  width: 336px;
}

.about-us .rectangle-41 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.83) 0%, rgba(0, 0, 0, 0) 89.28%);
  height: 116px;
  left: 29px;
  position: absolute;
  top: 458px;
  width: 336px;
}

.about-us .fogger-expert {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 800;
  left: 48px;
  letter-spacing: 2.4px;
  line-height: 19px;
  position: absolute;
  top: 534px;
  white-space: nowrap;
  width: 159px;
}

.about-us .kiara-reynolds {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 48px;
  letter-spacing: 0;
  line-height: 37.5px;
  position: absolute;
  top: 496px;
  white-space: nowrap;
  width: 258px;
}

.about-us .group-78 {
  height: 399px;
  left: 29px;
  position: absolute;
  top: 593px;
  width: 340px;
}

.about-us .overlap-group-7 {
  /* background-image: url(../../../../static/img/mask-group-102.png); */
  background-size: 100% 100%;
  height: 399px;
  position: relative;
  width: 336px;
}

.about-us .rectangle-42 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.83) 0%, rgba(0, 0, 0, 0) 89.28%);
  height: 116px;
  left: 1px;
  position: absolute;
  top: 283px;
  width: 335px;
}

.about-us .fogger-expert-2 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 800;
  left: 19px;
  letter-spacing: 2.4px;
  line-height: 19px;
  position: absolute;
  top: 362px;
  white-space: nowrap;
  width: 159px;
}

.about-us .joshua-tree {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 19px;
  letter-spacing: 0;
  line-height: 37.5px;
  position: absolute;
  top: 322px;
  width: 178px;
}

.about-us .vector-5 {
  height: 22px;
  left: 293px;
  position: absolute;
  top: 355px;
  width: 22px;
}

.about-us .mask-group-17 {
  height: 399px;
  left: 29px;
  position: absolute;
  top: 1010px;
  width: 336px;
}

.about-us .rectangle-43 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.83) 0%, rgba(0, 0, 0, 0) 89.28%);
  height: 113px;
  left: 28px;
  position: absolute;
  top: 1296px;
  width: 337px;
}

.about-us .fogger-expert-3 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 800;
  left: 52px;
  letter-spacing: 2.4px;
  line-height: 19px;
  position: absolute;
  top: 1369px;
  white-space: nowrap;
  width: 137px;
}

.about-us .stanley-miller {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 52px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 1331px;
  width: 203px;
}

.about-us .vector-6 {
  height: 22px;
  left: 320px;
  position: absolute;
  top: 525px;
  width: 22px;
}

.about-us .vector-7 {
  height: 22px;
  left: 324px;
  position: absolute;
  top: 1365px;
  width: 22px;
}

.about-us .overlap-35 {
  /* background-image: url(../../../../static/img/mask-group-96.png); */
  background-size: 100% 100%;
  height: 399px;
  left: 29px;
  position: absolute;
  top: 3927px;
  width: 336px;
}

.about-us .rectangle-44 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.83) 0%, rgba(0, 0, 0, 0) 89.28%);
  height: 115px;
  left: 0;
  position: absolute;
  top: 284px;
  width: 336px;
}

.about-us .fogger-expert-4 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 800;
  left: 19px;
  letter-spacing: 2.4px;
  line-height: 19px;
  position: absolute;
  top: 355px;
  white-space: nowrap;
  width: 160px;
}

.about-us .mitchell-baker {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 19px;
  letter-spacing: 0;
  line-height: 37.5px;
  position: absolute;
  top: 317px;
  width: 242px;
}

.about-us .vector-8 {
  height: 22px;
  left: 295px;
  position: absolute;
  top: 348px;
  width: 22px;
}

.about-us .overlap-36 {
  /* background-image: url(../../../../static/img/mask-group-105.png); */
  background-size: 100% 100%;
  height: 399px;
  left: 29px;
  position: absolute;
  top: 4349px;
  width: 336px;
}

.about-us .rectangle-45 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.83) 0%, rgba(0, 0, 0, 0) 89.28%);
  height: 116px;
  left: 0;
  position: absolute;
  top: 283px;
  width: 336px;
}

.about-us .fogger-expert-5 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 800;
  left: 19px;
  letter-spacing: 2.4px;
  line-height: 19px;
  position: absolute;
  top: 357px;
  white-space: nowrap;
  width: 151px;
}

.about-us .emilia-roberts {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 19px;
  letter-spacing: 0;
  line-height: 37.5px;
  position: absolute;
  top: 321px;
  width: 253px;
}

.about-us .vector-9 {
  height: 22px;
  left: 295px;
  position: absolute;
  top: 347px;
  width: 22px;
}

.about-us .overlap-37 {
  /* background-image: url(../../../../static/img/mask-group-107.png); */
  background-size: 100% 100%;
  height: 399px;
  left: 29px;
  position: absolute;
  top: 4771px;
  width: 336px;
}

.about-us .fogger-expert-6 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 800;
  left: 23px;
  letter-spacing: 2.4px;
  line-height: 19px;
  position: absolute;
  top: 360px;
  white-space: nowrap;
  width: 157px;
}

.about-us .michael-sullivan {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 23px;
  letter-spacing: 0;
  line-height: 37.5px;
  position: absolute;
  top: 322px;
  width: 279px;
}

.about-us .vector-10 {
  height: 22px;
  left: 295px;
  position: absolute;
  top: 353px;
  width: 22px;
}

.about-us .news-stories {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 600;
  left: 29px;
  letter-spacing: 0;
  line-height: 15px;
  opacity: 0.75;
  position: absolute;
  top: 5231px;
  width: 250px;
}

.about-us .in-the-press {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 45px;
  font-weight: 400;
  left: 29px;
  letter-spacing: -1.8px;
  line-height: 42.3px;
  position: absolute;
  top: 5270px;
  width: 336px;
}

.about-us .group-79 {
  display: block;
  height: 354px;
  left: 29px;
  position: absolute;
  top: 5435px;
  width: 321px;
}

.about-us .screenshot-9 {
  height: 189px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 317px;
}

.about-us .thursday-june-9 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 600;
  left: 1px;
  letter-spacing: 2.4px;
  line-height: 19px;
  position: absolute;
  top: 219px;
  white-space: nowrap;
}

.about-us .lorem-ipsum-dolor-12 {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 1px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 248px;
  width: 316px;
}

.about-us .instagram-feed {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 600;
  left: 30px;
  letter-spacing: 0;
  line-height: 15px;
  position: absolute;
  top: 5925px;
  width: 211px;
}

.about-us .overlap-38 {
  height: 57px;
  left: 30px;
  position: absolute;
  top: 5970px;
  width: 279px;
}

.about-us .text-wrapper-84 {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 45px;
  font-weight: 400;
  left: 0;
  letter-spacing: -1.8px;
  line-height: 42.3px;
  position: absolute;
  top: 0;
  width: 229px;
}

.about-us .arrow-7 {
  height: 54px;
  left: 225px;
  position: absolute;
  top: 3px;
  width: 54px;
}

.about-us .overlap-39 {
  height: 1078px;
  left: 0;
  position: absolute;
  top: 6505px;
  width: 390px;
}

.about-us .FOOTER-8 {
  left: 0 !important;
  position: absolute !important;
  top: 219px !important;
}

.FOOTER-instance {
  left: 0 !important;
  position: absolute !important;
  top: 7200px !important;
  z-index: 0;
}

.about-us .mask-group-18 {
  height: 219px;
  left: 0;
  position: absolute;
  top: 0;
  width: 193px;
}

.about-us .mask-group-19 {
  height: 219px;
  left: 197px;
  position: absolute;
  top: 0;
  width: 193px;
}

.about-us .mask-group-20 {
  height: 219px;
  left: 197px;
  position: absolute;
  top: 6059px;
  width: 193px;
}

.about-us .mask-group-21 {
  height: 219px;
  left: 197px;
  position: absolute;
  top: 6282px;
  width: 193px;
}

.about-us .mask-group-22 {
  height: 219px;
  left: 0;
  position: absolute;
  top: 6282px;
  width: 193px;
}

.about-us .mask-group-23 {
  height: 219px;
  left: 0;
  position: absolute;
  top: 6059px;
  width: 193px;
}

.about-us .thursday-june-10 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 600;
  left: 365px;
  letter-spacing: 2.4px;
  line-height: 19px;
  position: absolute;
  top: 5653px;
  white-space: nowrap;
}

.about-us .lorem-ipsum-dolor-13 {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 365px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 5682px;
  width: 316px;
}

.about-us .screenshot-10 {
  height: 189px;
  left: 365px;
  object-fit: cover;
  position: absolute;
  top: 5435px;
  width: 317px;
}

.about-us .group-80 {
  height: 4px;
  left: 30px;
  position: absolute;
  top: 5838px;
  width: 335px;
}

.about-us .filter-by-2 {
  color: #a1a1a1;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  left: 29px;
  letter-spacing: -0.32px;
  line-height: 18.7px;
  position: absolute;
  top: 5346px;
  width: 218px;
}

.about-us .line-63 {
  height: 1px;
  left: 29px;
  object-fit: cover;
  position: absolute;
  top: 5378px;
  width: 336px;
}

.about-us .vector-11 {
  height: 6px;
  left: 338px;
  position: absolute;
  top: 5358px;
  width: 11px;
}

.about-us .group-81 {
  background-color: #121212;
  height: 105px;
  left: 0;
  position: fixed;
  top: 0;
  width: 390px;
}

.about-us .NAV-8 {
  left: 29px !important;
  top: 39px !important;
}




.container {
  display: grid;
  place-items: center;
  height: 100vh !important;
  width: 100vw !important;
}

.NAV-instance {
  left: 29px !important;
  position: fixed !important;
  top: 39px !important;
}

.NAV {
  height: 42px;
  width: 347px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.NAV .logo {
  display: block;
  height: 39px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 140px;
}

.group-101 {
  height: 65px !important;
  left: 316px !important;
  position: fixed !important;
  top: 771px !important;
  width: 65px !important;
}

.NAV .group {
  height: 39px;
  position: relative;
  width: 142px;
}

.NAV .text-wrapper-6 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 44px;
  letter-spacing: -0.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 96px;
}

.NAV .img {
  height: 39px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 38px;
}

.NAV .menu-wrapper {
  display: block;
  height: 30px;
  left: 297px;
  position: absolute;
  top: 5px;
  width: 50px;
  cursor: pointer;
}

.NAV .menu-2 {
  height: 11px;
  left: 10px;
  position: relative;
  top: 16px;
  width: 33px;
}

.NAV .line {
  height: 2px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 33px;
}

.NAV .line-2 {
  height: 2px;
  left: 10px;
  position: absolute;
  top: 8px;
  width: 23px;
}

.side-menu {
  position: fixed;
  top: 0;
  right: -375px;
  height: 100%;
  width: 375px;
  background-color: #121212;
  transition: right 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.side-menu.open {
  right: 0;
}

.side-menu a {
  color: white;
  text-decoration: none;
  margin: 10px 0;
}

/* end of about-us */
}











/* added in */
body {
  background-color: #121212 !important;
}

.section_white {
  background-color: #ffffff !important;
}
#section_white {
  background-color: #ffffff !important;
}
#section_black {
  background-color: #121212!important;
}

.section_black {
  background-color: #121212 !important;
}

#z-index--1 {
  z-index: -1 !important;
}

.z-index--1 {
  z-index: -1 !important;
}

#z-index-0 {
  z-index: 0 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

#z-index-1 {
  z-index: 1 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

#z-index-2 {
  z-index: 2 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-max {
  z-index: 2147483647 !important;
  
}

#z-index-max {
  z-index: 2147483647 !important;
  
}

/* #z-index-999 {
  z-index: 99999999999999999999999 !important;
}

.z-index-999 {
  z-index: 99999999999999999999999 !important;
} */



/* for overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(18, 18, 18, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.overlay-content {
  position: relative;
  background: #121212;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.overlay-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

/* end of for overlay */

/* for call */

.call {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.call .call-wrapper {
  background-color: #000000;
  height: 1080px;
  overflow: hidden;
  width: 702px;
}

.call .div-16 {
  background-color: #1e1e1e;
  height: 1080px;
  left: 0px;
  position: relative;
  top: 0px;
}

.call .group-44 {
  height: 89px;
  left: 91px;
  position: absolute;
  top: 895px;
  width: 179px;
}

.call .group-45 {
  border: 0.5px solid;
  border-color: #ffffff;
  border-radius: 44.5px;
  height: 89px;
  left: 88px;
  position: absolute;
  top: 0;
  width: 89px;
}

.call .arrow-9 {
  height: 32px;
  left: 28px;
  position: absolute;
  top: 28px;
  width: 32px;
}

.call .submit-4 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 600;
  left: 0;
  letter-spacing: 2.8px;
  line-height: 22.1px;
  position: absolute;
  top: 36px;
  white-space: nowrap;
}

.call .get-in-touch {
  color: #ffffff;
  font-family: var(--h4-font-family);
  font-size: var(--h4-font-size);
  font-style: var(--h4-font-style);
  font-weight: var(--h4-font-weight);
  left: 89px;
  letter-spacing: var(--h4-letter-spacing);
  line-height: var(--h4-line-height);
  position: absolute;
  top: 65px;
  width: 385px;
}

.call .overlap-28 {
  border: 2px solid;
  border-color: #d0d0d0;
  border-radius: 5px;
  height: 93px;
  left: 89px;
  position: absolute;
  top: 214px;
  width: 169px;
}

.call .text-wrapper-153 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 24px;
  font-weight: 700;
  left: 20px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  top: 43px;
  width: 131px;
}

.call .overlap-29 {
  border: 2px solid;
  border-color: #d0d0d059;
  border-radius: 5px;
  height: 93px;
  left: 275px;
  position: absolute;
  top: 214px;
  width: 169px;
}

.call .text-wrapper-154 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 24px;
  font-weight: 700;
  left: 20px;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.35;
  position: absolute;
  top: 43px;
  width: 131px;
}

.call .IPM-wrapper {
  border: 2px solid;
  border-color: #d0d0d059;
  border-radius: 5px;
  height: 93px;
  left: 461px;
  position: absolute;
  top: 214px;
  width: 169px;
}

.call .IPM {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 24px;
  font-weight: 700;
  left: 19px;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.35;
  position: absolute;
  top: 43px;
  width: 131px;
}

.call .group-wrapper {
  height: 60px;
  left: 89px;
  position: absolute;
  top: 355px;
  width: 541px;
}

.call .group-46 {
  height: 60px;
  width: 543px;
}

.call .phone-wrapper {
  background-color: #ffffff;
  border-radius: 5px;
  height: 60px;
  position: relative;
  width: 541px;
}

.call .phone {
  color: #1e1e1e;
  font-family: "Lato", Helvetica;
  font-size: 18px;
  font-weight: 600;
  left: 48px;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  text-align: center;
  top: 18px;
  white-space: nowrap;
  width: 446px;
}

.call .first-name-3 {
  background: transparent;
  border: none;
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 90px;
  letter-spacing: 0;
  line-height: 22.5px;
  padding: 0;
  position: absolute;
  top: 551px;
  width: 189px;
}

.call .last-name-4 {
  background: transparent;
  border: none;
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 377px;
  letter-spacing: 0;
  line-height: 22.5px;
  padding: 0;
  position: absolute;
  top: 551px;
  width: 189px;
}

.call .line-81 {
  height: 1px;
  left: 90px;
  object-fit: cover;
  position: absolute;
  top: 581px;
  width: 254px;
}

.call .line-82 {
  height: 1px;
  left: 377px;
  object-fit: cover;
  position: absolute;
  top: 581px;
  width: 254px;
}

.call .phone-number-4 {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 376px;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  top: 620px;
  width: 239px;
}

.call .line-83 {
  height: 1px;
  left: 376px;
  object-fit: cover;
  position: absolute;
  top: 651px;
  width: 254px;
}

.call .email-address-4 {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 90px;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  top: 620px;
  white-space: nowrap;
  width: 266px;
}

.call .line-84 {
  height: 1px;
  left: 90px;
  object-fit: cover;
  position: absolute;
  top: 651px;
  width: 253px;
}

.call .location-state {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 90px;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  top: 690px;
  white-space: nowrap;
  width: 266px;
}

.call .line-85 {
  height: 1px;
  left: 90px;
  object-fit: cover;
  position: absolute;
  top: 721px;
  width: 540px;
}

.call .vector-6 {
  height: 6px;
  left: 605px;
  position: absolute;
  top: 699px;
  width: 11px;
}

.call .interested-in-4 {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 90px;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  top: 760px;
  white-space: nowrap;
  width: 266px;
}

.call .vector-7 {
  height: 6px;
  left: 605px;
  position: absolute;
  top: 769px;
  width: 11px;
}

.call .line-86 {
  height: 1px;
  left: 90px;
  object-fit: cover;
  position: absolute;
  top: 791px;
  width: 540px;
}

.call .message-4 {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 89px;
  letter-spacing: 0;
  line-height: 22.5px;
  position: absolute;
  top: 830px;
  white-space: nowrap;
  width: 266px;
}

.call .line-87 {
  height: 1px;
  left: 89px;
  object-fit: cover;
  position: absolute;
  top: 861px;
  width: 543px;
}

.call .line-88 {
  height: 1px;
  left: 89px;
  object-fit: cover;
  position: absolute;
  top: 481px;
  width: 543px;
}

.call .group-47 {
  height: 77px;
  left: 582px;
  position: absolute;
  top: 63px;
  width: 77px;
}

.call .ph-x-wrapper {
  background-color: #ffffff;
  border-radius: 38.5px;
  box-shadow: 0px 0px 10px #00000026;
  height: 77px;
  position: relative;
}

.call .ph-x {
  height: 36px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 36px;
}

/* end of for call */

.hidden {
  display: none !important;
}

.container {
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
}

.NAV {
  height: 42px;
  width: 347px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.NAV .logo {
  display: block;
  height: 39px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 140px;
}

.NAV .group {
  height: 39px;
  position: relative;
  width: 142px;
}

.NAV .text-wrapper-6 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 44px;
  letter-spacing: -0.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 96px;
}

.NAV .img {
  height: 39px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 38px;
}

.NAV .menu-wrapper {
  display: block;
  height: 30px;
  left: 297px;
  position: absolute;
  top: 5px;
  width: 50px;
  cursor: pointer;
}

.NAV .menu-2 {
  height: 11px;
  left: 10px;
  position: relative;
  top: 16px;
  width: 33px;
}

.NAV .line {
  height: 2px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 33px;
}

.NAV .line-2 {
  height: 2px;
  left: 10px;
  position: absolute;
  top: 8px;
  width: 23px;
}

.side-menu {
  position: fixed;
  top: 0;
  right: -375px; /* Adjusted from 250px to 300px to accommodate the wider width */
  height: 100%;
  width: 375px; /* Changed to 335px to take up the entire screen width for mobile */
  background-color: #121212;
  transition: right 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 1000;
}

.side-menu.open {
  right: 0;
}

.side-menu a {
  color: white;
  text-decoration: none;
  margin: 10px 0;
}

.menu {
  background-color: #121212;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.menu .menu-wrapper-2 {
  height: 844px;
  overflow: hidden;
  width: 390px;
}

.menu .div-6 {
  background-color: #121212;
  height: 844px;
  position: relative;
  left: 0px;
  top: 0px;
}

.menu .group-23 {
  height: 54px;
  left: 305px;
  position: absolute;
  top: 37px;
  width: 54px;
}

.menu .overlap-19 {
  border-radius: 27px;
  height: 54px;
  position: relative;
}

.menu .group-24 {
  height: 16px;
  left: 19px;
  position: absolute;
  top: 19px;
  width: 16px;
}

.menu .line-wrapper {
  background-image: url(/public/img/line-1-21.svg);
  background-size: 100% 100%;
  height: 16px;
  position: relative;
  top: -1px;
  width: 17px;
}

.menu .line-22 {
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}

.menu .circle-2 {
  border: 0.5px solid;
  border-color: #ffffff;
  border-radius: 27px;
  height: 54px;
  left: 0;
  position: absolute;
  top: 0;
  width: 54px;
}

.menu .careers-merchandise {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 50px;
  letter-spacing: 0;
  line-height: 56.3px;
  position: absolute;
  top: 498px;
  width: 145px;
}

.menu .overlap-20 {
  height: 113px;
  left: 50px;
  position: absolute;
  top: 362px;
  width: 309px;
}

.menu .resources {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 50px;
  font-weight: 400;
  left: 0;
  letter-spacing: -2px;
  line-height: 57.5px;
  position: absolute;
  top: 55px;
  width: 309px;
}

.menu .subscriptions {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 50px;
  font-weight: 400;
  left: 0;
  letter-spacing: -2px;
  line-height: 57.5px;
  position: absolute;
  top: 0;
  width: 309px;
}

.menu .line-23 {
  height: 1px;
  left: 50px;
  object-fit: cover;
  position: absolute;
  top: 702px;
  width: 272px;
}

.menu .frame-4 {
  align-items: flex-start;
  display: flex;
  gap: 30px;
  height: 45px;
  left: 50px;
  position: absolute;
  top: 733px;
  width: 272px;
}

.menu .img-wrapper-2 {
  border: 0.5px solid;
  border-color: #ffffff;
  border-radius: 22.5px;
  height: 45px;
  position: relative;
  width: 45px;
}

.menu .ic-baseline-tiktok-2 {
  height: 24px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 24px;
}

.menu .overlap-group-wrapper-2 {
  height: 45px;
  position: relative;
  width: 45px;
}

.menu .overlap-group-5 {
  border-radius: 22.5px;
  height: 45px;
  position: relative;
}

.menu .ant-design-instagram-3 {
  height: 22px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 22px;
}

.menu .ellipse-8 {
  border: 0.5px solid;
  border-color: #ffffff;
  border-radius: 22.5px;
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.menu .flowbite-x-solid-2 {
  height: 17px;
  left: 14px;
  position: absolute;
  top: 14px;
  width: 17px;
}

.menu .ant-design-youtube-2 {
  height: 19px;
  left: 14px;
  position: absolute;
  top: 14px;
  width: 19px;
}

.menu .ipm-products {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 50px;
  font-weight: 400;
  left: 50px;
  letter-spacing: -2px;
  line-height: 57.5px;
  position: absolute;
  top: 304px;
  width: 309px;
}

.menu .our-systems {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 50px;
  font-weight: 400;
  left: 50px;
  letter-spacing: -2px;
  line-height: 57.5px;
  position: absolute;
  top: 246px;
  width: 309px;
}

.menu .about-us {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 50px;
  font-weight: 400;
  left: 50px;
  letter-spacing: -2px;
  line-height: 57.5px;
  position: absolute;
  top: 188px;
  width: 309px;
}

.menu .home-2 {
  color: #ffffff;
  display: block;
  font-family: "Basic", Helvetica;
  font-size: 50px;
  font-weight: 400;
  left: 50px;
  letter-spacing: -2px;
  line-height: 57.5px;
  position: absolute;
  top: 130px;
  width: 309px;
}








/* custom-styles.css */
.border-bottom-only {
    border: none; /* Remove default border */
    border-bottom: 2px solid #000; /* Add border-bottom with desired color and thickness */
    padding: 8px 0; /* Optional: add some padding for better spacing */
    box-sizing: border-box; /* Ensure padding does not affect the width */
    padding-bottom: 5px;
    margin-bottom: 20px; /* Add 20px padding to the bottom */
    color: #000 !important; /* Set font color to #000 */


  }

  /* Optional: add focus state for better user experience */
  .border-bottom-only:focus {
    outline: none; /* Remove default focus outline */
    border-bottom-color: #007bff; /* Change border color on focus */
  }


  /* Apply styles to all input fields */
input[type="text"].border-bottom-only,
input[type="password"].border-bottom-only,
input[type="email"].border-bottom-only,
input[type="number"].border-bottom-only,
input[type="tel"].border-bottom-only,
input[type="url"].border-bottom-only,
input[type="search"].border-bottom-only,
textarea.border-bottom-only,
select.border-bottom-only {
  border: none; /* Remove default border */
  border-bottom: 2px solid #000; /* Add border-bottom with desired color and thickness */
  padding-bottom: 20px; /* Add 20px padding to the bottom */
  padding-top: 8px; /* Optional: add some padding for better spacing */
  box-sizing: border-box; /* Ensure padding does not affect the width */
  color: #000; /* Set font color to #000 */
}

/* custom-styles.css */
.border-bottom-only {
    border: none; /* Remove default border */
    border-bottom: 2px solid #000; /* Add border-bottom with desired color and thickness */
    padding: 8px 0; /* Optional: add some padding for better spacing */
    box-sizing: border-box; /* Ensure padding does not affect the width */
    padding-bottom: 5px;
    margin-bottom: 20px; /* Add 20px padding to the bottom */
    color: #000 !important; /* Set font color to #000 */


  }

  /* Optional: add focus state for better user experience */
  .border-bottom-only:focus {
    outline: none; /* Remove default focus outline */
    border-bottom-color: #007bff; /* Change border color on focus */
  }


  /* Apply styles to all input fields */
input[type="text"].border-bottom-only,
input[type="password"].border-bottom-only,
input[type="email"].border-bottom-only,
input[type="number"].border-bottom-only,
input[type="tel"].border-bottom-only,
input[type="url"].border-bottom-only,
input[type="search"].border-bottom-only,
textarea.border-bottom-only,
select.border-bottom-only {
  border: none; /* Remove default border */
  border-bottom: 2px solid #000; /* Add border-bottom with desired color and thickness */
  padding-bottom: 20px; /* Add 20px padding to the bottom */
  padding-top: 8px; /* Optional: add some padding for better spacing */
  box-sizing: border-box; /* Ensure padding does not affect the width */
  color: #000; /* Set font color to #000 */
}

/* Optional: add focus state for better user experience */
input[type="text"].border-bottom-only:focus,
input[type="password"].border-bottom-only:focus,
input[type="email"].border-bottom-only:focus,
input[type="number"].border-bottom-only:focus,
input[type="tel"].border-bottom-only:focus,
input[type="url"].border-bottom-only:focus,
input[type="search"].border-bottom-only:focus,
textarea.border-bottom-only:focus,
select.border-bottom-only:focus {
  outline: none; /* Remove default focus outline */
  border-bottom-color: #007bff; /* Change border color on focus */
}

/* Optional: add focus state for better user experience */
input[type="text"].border-bottom-only:focus,
input[type="password"].border-bottom-only:focus,
input[type="email"].border-bottom-only:focus,
input[type="number"].border-bottom-only:focus,
input[type="tel"].border-bottom-only:focus,
input[type="url"].border-bottom-only:focus,
input[type="search"].border-bottom-only:focus,
textarea.border-bottom-only:focus,
select.border-bottom-only:focus {
  outline: none; /* Remove default focus outline */
  border-bottom-color: #007bff; /* Change border color on focus */
}


/* custom-styles.css */
.custom-submit-button {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.custom-submit-button img {
  margin-right: 8px; /* Adjust as necessary */
}

/* .custom-submit-button:hover {
  /* Optional: Add hover effects */
/* } */


/* custom-styles-black-bg.css */
.border-bottom-only-black-bg {
  border: none; /* Remove default border */
  border-bottom: 2px solid #fff; /* Add border-bottom with desired color and thickness */
  padding: 8px 0; /* Optional: add some padding for better spacing */
  box-sizing: border-box; /* Ensure padding does not affect the width */
  padding-bottom: 5px;
  margin-bottom: 20px; /* Add 20px padding to the bottom */
  color: #fff !important; /* Set font color to #fff */
}

/* Optional: add focus state for better user experience */
.border-bottom-only-black-bg:focus {
  outline: none; /* Remove default focus outline */
  border-bottom-color: #fff; /* Change border color on focus */
}

/* Apply styles to all input fields */
input[type="text"].border-bottom-only-black-bg,
input[type="password"].border-bottom-only-black-bg,
input[type="email"].border-bottom-only-black-bg,
input[type="number"].border-bottom-only-black-bg,
input[type="tel"].border-bottom-only-black-bg,
input[type="url"].border-bottom-only-black-bg,
input[type="search"].border-bottom-only-black-bg,
textarea.border-bottom-only-black-bg,
select.border-bottom-only-black-bg {
  border: none; /* Remove default border */
  border-bottom: 2px solid #fff; /* Add border-bottom with desired color and thickness */
  padding-bottom: 20px; /* Add 20px padding to the bottom */
  padding-top: 8px; /* Optional: add some padding for better spacing */
  box-sizing: border-box; /* Ensure padding does not affect the width */
  color: #fff; /* Set font color to #fff */
  margin-bottom: 20px; /* Add 20px padding to the bottom */

}

/* Optional: add focus state for better user experience */
input[type="text"].border-bottom-only-black-bg:focus,
input[type="password"].border-bottom-only-black-bg:focus,
input[type="email"].border-bottom-only-black-bg:focus,
input[type="number"].border-bottom-only-black-bg:focus,
input[type="tel"].border-bottom-only-black-bg:focus,
input[type="url"].border-bottom-only-black-bg:focus,
input[type="search"].border-bottom-only-black-bg:focus,
textarea.border-bottom-only-black-bg:focus,
select.border-bottom-only-black-bg:focus {
  outline: none; /* Remove default focus outline */
  border-bottom-color: #007bff; /* Change border color on focus */
}

/* custom-styles-black-bg.css */
.custom-submit-button-black-bg {
  cursor: pointer;
  display: flex;
  align-items: center;

  background-color: #000; /* Set background color to black */
  color: #fff; /* Set font color to white */
}

.custom-submit-button-black-bg img {
  margin-right: 8px; /* Adjust as necessary */
}

.custom-submit-button-black-bg:hover {
  /* Optional: Add hover effects */
  background-color: #333; /* Darken background on hover */
}


/*
.menuButton {
} */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.overlayOpen {
  transform: translateX(0);
}

.menuContent {
  position: relative;
  background: white;
  width: 300px;
  height: 100%;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.menuButton {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1001;
}


.container {
display: grid;
place-items: center;
height: 100vh;
width: 100vw;
}

.NAV {
height: 42px;
width: 347px;
display: flex;
flex-direction: row;
justify-content: center;
position: relative;
}

.NAV .logo {
display: block;
height: 39px;
left: 0;
position: absolute;
top: 3px;
width: 140px;
}

.NAV .group {
height: 39px;
position: relative;
width: 142px;
}

.NAV .text-wrapper-6 {
color: #ffffff;
font-family: "Lato", Helvetica;
font-size: 20px;
font-weight: 700;
left: 44px;
letter-spacing: -0.4px;
line-height: normal;
position: absolute;
text-align: center;
top: 7px;
width: 96px;
}

.NAV .img {
height: 39px;
left: 0;
object-fit: cover;
position: absolute;
top: 0;
width: 38px;
}

.NAV .menu-wrapper {
display: block;
height: 30px;
left: 297px;
position: absolute;
top: 5px;
width: 50px;
cursor: pointer;
}

.NAV .menu-2 {
height: 11px;
left: 10px;
position: relative;
top: 16px;
width: 33px;
}

.NAV .line {
height: 2px;
left: 0;
position: absolute;
top: -2px;
width: 33px;
}

.NAV .line-2 {
height: 2px;
left: 10px;
position: absolute;
top: 8px;
width: 23px;
}

/* .side-menu {
position: fixed;
top: 0;
right: -375px;
height: 100%;
width: 375px;
background-color: #121212;
transition: right 0.3s ease-in-out;
display: flex;
flex-direction: column;
padding: 10px;
} */

.side-menu {
  position: fixed;
  top: 0;
  right: -100vw; /* Initially positioned off-screen */
  height: 100%;
  width: 100vw; /* Full viewport width */
  background-color: #121212;
  transition: right 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 1000;
}

.side-menu.open {
right: 0;
}


.side-menu a {
color: white;
text-decoration: none;
margin: 10px 0;
}

.menu {
background-color: #121212;
display: flex;
flex-direction: row;
justify-content: center;
width: 100%;
}

.menu-wrapper-2 {
height: 844px;
overflow: hidden;
width: 390px;
}

.div-6 {
background-color: #121212;
height: 844px;
position: relative;
left: 0px;
top: 0px;
}

.group-23 {
height: 54px;
left: 305px;
position: absolute;
top: 37px;
width: 54px;
}

.overlap-19 {
border-radius: 27px;
height: 54px;
position: relative;
}

.group-24 {
height: 16px;
left: 19px;
position: absolute;
top: 19px;
width: 16px;
}

.line-wrapper {
/* background-image: url('../public/img/line-1-21.svg'); */
background-size: 100% 100%;
height: 16px;
position: relative;
top: -1px;
width: 17px;
}

.line-22 {
height: 16px;
left: 0;
position: absolute;
top: 0;
width: 16px;
}

.circle-2 {
border: 0.5px solid;
border-color: #ffffff;
border-radius: 27px;
height: 54px;
left: 0;
position: absolute;
top: 0;
width: 54px;
}

.careers-merchandise {
color: #ffffff;
font-family: "Lato", Helvetica;
font-size: 18px;
font-weight: 400;
left: 50px;
letter-spacing: 0;
line-height: 56.3px;
position: absolute;
top: 498px;
width: 145px;
}

.overlap-20 {
height: 113px;
left: 50px;
position: absolute;
top: 362px;
width: 309px;
}

.resources {
color: #ffffff;
font-family: "Basic", Helvetica;
font-size: 50px;
font-weight: 400;
left: 0;
letter-spacing: -2px;
line-height: 57.5px;
position: absolute;
top: 55px;
width: 309px;
}

.subscriptions {
color: #ffffff;
font-family: "Basic", Helvetica;
font-size: 50px;
font-weight: 400;
left: 0;
letter-spacing: -2px;
line-height: 57.5px;
position: absolute;
top: 0;
width: 309px;
}

.line-23 {
height: 1px;
left: 50px;
object-fit: cover;
position: absolute;
top: 702px;
width: 272px;
}

.frame-4 {
align-items: flex-start;
display: flex;
gap: 30px;
height: 45px;
left: 50px;
position: absolute;
top: 733px;
width: 272px;
}

.img-wrapper-2 {
border: 0.5px solid;
border-color: #ffffff;
border-radius: 22.5px;
height: 45px;
position: relative;
width: 45px;
}

.ic-baseline-tiktok-2 {
height: 24px;
left: 10px;
position: absolute;
top: 10px;
width: 24px;
}

.overlap-group-wrapper-2 {
height: 45px;
position: relative;
width: 45px;
}

.overlap-group-5 {
border-radius: 22.5px;
height: 45px;
position: relative;
}

.ant-design-instagram-3 {
height: 22px;
left: 12px;
position: absolute;
top: 12px;
width: 22px;
}

.ellipse-8 {
border: 0.5px solid;
border-color: #ffffff;
border-radius: 22.5px;
height: 45px;
left: 0;
position: absolute;
top: 0;
width: 45px;
}

.flowbite-x-solid-2 {
height: 17px;
left: 14px;
position: absolute;
top: 14px;
width: 17px;
}

.ant-design-youtube-2 {
height: 19px;
left: 14px;
position: absolute;
top: 14px;
width: 19px;
}

.ipm-products {
color: #ffffff;
font-family: "Basic", Helvetica;
font-size: 50px;
font-weight: 400;
left: 50px;
letter-spacing: -2px;
line-height: 57.5px;
position: absolute;
top: 304px;
width: 309px;
}

.our-systems {
color: #ffffff;
font-family: "Basic", Helvetica;
font-size: 50px;
font-weight: 400;
left: 50px;
letter-spacing: -2px;
line-height: 57.5px;
position: absolute;
top: 246px;
width: 309px;
}

.about-us {
color: #ffffff;
font-family: "Basic", Helvetica;
font-size: 50px;
font-weight: 400;
left: 50px;
letter-spacing: -2px;
line-height: 57.5px;
position: absolute;
top: 188px;
width: 309px;
}

.home-2 {
color: #ffffff;
display: block;
font-family: "Basic", Helvetica;
font-size: 50px;
font-weight: 400;
left: 50px;
letter-spacing: -2px;
line-height: 57.5px;
position: absolute;
top: 130px;
width: 309px;
}

.menu {
  background-color: #121212;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.menu .menu-wrapper-2 {
  height: 844px;
  overflow: hidden;
  width: 390px;
}

.menu .div-6 {
  background-color: #121212;
  height: 844px;
  position: relative;
  left: 0;
  top: 0;
}

.menu .careers-merchandise,
.menu .resources,
.menu .subscriptions,
.menu .ipm-products,
.menu .our-systems,
.menu .about-us,
.menu .home-2 {
  color: #ffffff;
  font-family: "Basic", Helvetica;
  font-size: 50px;
  font-weight: 400;
  letter-spacing: -2px;
  line-height: 57.5px;
  position: absolute;
  left: 0;
  width: 309px;
}

.menu .careers-merchandise {
  font-size: 18px;
  line-height: 56.3px;
  top: 498px;
}

.menu .resources {
  top: 55px;
}

.menu .subscriptions {
  top: 0;
}

.menu .ipm-products {
  top: 304px;
}

.menu .our-systems {
  top: 246px;
}

.menu .about-us {
  top: 188px;
}

.menu .home-2 {
  top: 130px;
}

.menu .line-23 {
  height: 1px;
  left: 50px;
  object-fit: cover;
  position: absolute;
  top: 702px;
  width: 272px;
}

.menu .frame-4 {
  align-items: flex-start;
  display: flex;
  gap: 30px;
  height: 45px;
  left: 50px;
  position: absolute;
  top: 733px;
  width: 272px;
}

.menu .img-wrapper-2 {
  border: 0.5px solid;
  border-color: #ffffff;
  border-radius: 22.5px;
  height: 45px;
  position: relative;
  width: 45px;
}

.menu .ic-baseline-tiktok-2 {
  height: 24px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 24px;
}

.menu .overlap-group-wrapper-2 {
  height: 45px;
  position: relative;
  width: 45px;
}

.menu .overlap-group-5 {
  border-radius: 22.5px;
  height: 45px;
  position: relative;
}

.menu .ant-design-instagram-3 {
  height: 22px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 22px;
}

.menu .ellipse-8 {
  border: 0.5px solid;
  border-color: #ffffff;
  border-radius: 22.5px;
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.menu .flowbite-x-solid-2 {
  height: 17px;
  left: 14px;
  position: absolute;
  top: 14px;
  width: 17px;
}

.menu .ant-design-youtube-2 {
  height: 19px;
  left: 14px;
  position: absolute;
  top: 14px;
  width: 19px;
}
